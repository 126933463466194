export default [
  {
    header: 'Dashboard',
  },
  {
    title: "Home",
    route: "Home",
    icon: "HomeIcon",
  },
  
  {
    title: "My Results",
    route: "studentResult",
    icon: "BookIcon",
  },


  // {
  //   title: "E-learning",
  //   route: "member",
  //   icon: "CpuIcon",
  // },


  {
    header: 'Admin',
  },

  {
    title: "Change Password",
    route: "change-password",
    icon: "KeyIcon",
  },
];
