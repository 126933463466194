export default [
  {
    header: 'Dashboard',
  },
  {
    title: "Home",
    route: "Home",
    icon: "HomeIcon",
  },
  
  {
    title: "My Form Classes",
    route: "formClass",
    icon: "AwardIcon",
  },

  {
    title: "My Subject Classes",
    route: "subjectClass",
    icon: "AirplayIcon",
  },

  {
    header: 'Academics',
  },

  {
    title: 'Result System',
    icon: 'BookIcon',
    children: [
     {
       title: "Add Result",
       route: "add-result",
       icon: "BookIcon",
     },
     
     {
      title: "Add Psychomoto",
      route: "add-psychomoto",
      icon: "BookIcon",
    },
     {
       title: "View Result",
       route: "view-result",
       icon: "BookIcon",
     },
     
    ],
  },

  {
    title: 'Attendance',
    icon: 'UserIcon',
    children: [
     {
      title: "Report",
      route: "attendanceReport",
      icon: "UserIcon",
     },
   
     {
      title: "Barcode Scan",
      route: "barcode",
      icon: "UserIcon",
     },

    //  {
    //   title: "Staff Enrollment",
    //   route: "staffenroll",
    //   icon: "AwardIcon",
    //  },
   
    ],
  },

  {
    title: "E-learning",
    route: "member",
    icon: "CpuIcon",
  },


  {
    header: 'Admin',
  },

  // {
  //   title: "Logout",
  //   route: "logout",
  //   icon: "PowerIcon",
  // },
];
