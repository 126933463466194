import Dashboard from "./dashboard";

export default [
  ...Dashboard,
  {
    header: 'Dashboard',
  },
  {
    title: "Home",
    route: "Home",
    icon: "HomeIcon",
  },
  
  {
    title: 'School Setup',
    icon: 'SettingsIcon',
    children: [
   
      {
        title: "Basic Settings",
        route: "basic-settings",
        icon: "SettingsIcon",
      },

      {
        title: "Academic Settings",
        route: "academic-settings",
        icon: "SettingsIcon",
      },

      {
        title: "School Sessions",
        route: "sessions",
        icon: "SettingsIcon",
      },
    ],
  },

  {
    title: 'Admissions',
    icon: 'AwardIcon',
    children: [
     {
      title: "Single Student Enrollment",
      route: "enroll",
      icon: "AwardIcon",
     },
   
    //  {
    //   title: "Mass Student Enrollment",
    //   route: "mass-enroll",
    //   icon: "AwardIcon",
    //  },

     {
      title: "Staff Enrollment",
      route: "staffenroll",
      icon: "AwardIcon",
     },
   
    ],
  },

  {
   title: 'Administration',
   icon: 'AirplayIcon',
   children: [
    {
      title: "Subjects",
      route: "subjects",
      icon: "GridIcon",
    },
    
    {
      title: "Classes",
      route: "classes",
      icon: "AirplayIcon",
    },
  
    {
      title: "Learners",
      route: "student",
      icon: "MehIcon",
    },
  
    {
      title: "Staff",
      route: "staff",
      icon: "UserIcon",
    },
  
    {
      title: "Parents",
      route: "member",
      icon: "UsersIcon",
    },
   ],
  },

  // {
  //   title: "Attendance",
  //   route: "attendanceReport",
  //   icon: "UserIcon",
  // },

  {
    title: 'Attendance',
    icon: 'UserIcon',
    children: [
      {
        title: "Learner's Report",
        route: "attendanceReport",
        icon: "UserIcon",
       },
     
       {
        title: "Scan Learner",
        route: "barcode",
        icon: "UserIcon",
       },

       {
        title: "Staff's Report",
        route: "staffAttendanceReport",
        icon: "UserIcon",
       },
     
       {
        title: "Scan Staff",
        route: "staffBarcode",
        icon: "UserIcon",
       },

    //  {
    //   title: "Staff Enrollment",
    //   route: "staffenroll",
    //   icon: "AwardIcon",
    //  },
   
    ],
  },

  {
    header: 'Academics',
  },

  {
    title: 'Result System',
    icon: 'BookIcon',
    children: [
     {
       title: "Add Result",
       route: "add-result",
       icon: "BookIcon",
     },
     
     {
      title: "Add Psychomoto",
      route: "add-psychomoto",
      icon: "BookIcon",
    },
     {
       title: "View Result",
       route: "view-result",
       icon: "BookIcon",
     },
     
    ],
  },

  {
    title: "E-learning",
    route: "member",
    icon: "CpuIcon",
  },

  // {
  //   title: "Library",
  //   route: "sections",
  //   icon: "FileIcon",
  // },

  {
    header: 'Admin',
  },

  {
    title: "Change Password",
    route: "change-password",
    icon: "KeyIcon",
  },
];
